import React, { useContext } from "react";
import logo_recortado from "../../assets/images/logo_recortado.png";
import amex from "../../assets/images/amex.png";
import mastercard from "../../assets/images/mastercard.png";
import visa from "../../assets/images/visa.png";
import { AppConfigContext } from "../../context/AppConfigContext";
import { Link } from "@reach/router";

const Footer = () => {
  const {
    email,
    facebook,
    instagram,
    location
  } = useContext(AppConfigContext);
  

  return (
    <div className="footer bg-dark py-5">
      <div className="container px-0">
        <img alt="logo" src={logo_recortado} className="logo-footer" />
      </div>
      <div className="container text-white mt-5">
        <div className="row">
          <div className="col-12 col-md-4 my-3">
            <h4 className="bold">CONTACTO</h4>
            <a
              href="mailto: bikevibemty@gmail.com"
              className="text-white no-decoration d-inline-block mb-3"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa fa-envelope me-2 text-primary"></i>{" "}
              {email}
            </a>
            <a
              href={facebook}
              className="text-white no-decoration"
              target="_blank"
              rel="noreferrer"
            >
              <p>
                <i className="fab fa-facebook me-2 text-primary"></i> Bike Vibe
              </p>
            </a>
            <a
              href={instagram}
              className="text-white no-decoration"
              target="_blank"
              rel="noreferrer"
            >
              <p>
                <i className="fab fa-instagram me-2 text-primary"></i>{" "}
                @bikevibemx
              </p>
            </a>
          </div>
          <div className="col-12 col-md-4 my-3 pe-5">
            <h4 className="bold">UBICACIÓN</h4>
            <p>
              <i className="fa fa-map-pin text-primary me-1"></i> 
              {location}
            </p>
            <h4 className="mt-4 bold">INFORMACIÓN</h4>
            <Link
              to="/terminos-y-condiciones"
              className="text-white hover-primary d-block mb-2 no-decoration"
            >
              Términos y Condiciones
            </Link>
            <Link
              to="/privacidad"
              className="text-white hover-primary d-block mb-2 no-decoration"
            >
              Aviso de Privacidad
            </Link>
          </div>
          <div className="col-12 col-md-4 my-3">
            <h4 className="bold">ACEPTAMOS</h4>
            <img src={amex} className="payment-method me-2" />
            <img src={mastercard} className="payment-method me-2" />
            <img src={visa} className="payment-method me-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

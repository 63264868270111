
import React, { createContext, useReducer, useContext } from 'react';
import AvailableClassesService from '../services/AvailableClassesService';
import AvailableClassesReducer from '../reducers/AvailableClassesReducer';
import {
  AVAILABLE_CLASSS_RECEIVED,
  SET_AVAILABLE_CLASS,
  CREATE_AVAILABLE_CLASS,
  SET_PROPERTY_AVAILABLE_CLASS,
} from "../types/available_classes";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  available_classes: null,
  available_class: null,
};

export const AvailableClassesContext = createContext(initialState);

export const AvailableClassesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AvailableClassesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getAvailableClasses = () => {
    AvailableClassesService.getAvailableClasses()
      .then((response) => {
        const { available_classes } = response.data;
        dispatch({ type: AVAILABLE_CLASSS_RECEIVED, payload: available_classes });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleAvailableClass = (available_class_id) => {
    AvailableClassesService.getSingleAvailableClass(available_class_id)
      .then((response) => {
        const { available_class } = response.data;
        dispatch({ type: SET_AVAILABLE_CLASS, payload: available_class });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setAvailableClass = (available_class) => {
    dispatch({ type: SET_AVAILABLE_CLASS, payload: available_class });
  };

  const createAvailableClass = () => {
    dispatch({ type: CREATE_AVAILABLE_CLASS });
  };

  const setPropertyAvailableClass = (key, value) => {
    dispatch({ type: SET_PROPERTY_AVAILABLE_CLASS, payload: { key, value } });
  };

  const saveAvailableClass = (available_class, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = AvailableClassesService.putAvailableClass;
      if(isNaN(parseInt(available_class.available_class_id))) {
        service = AvailableClassesService.postAvailableClass;
      }
      service(available_class).then(() => {
        success("AvailableClass saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteAvailableClass = (available_class_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    AvailableClassesService.deleteAvailableClass(available_class_id).then(() => {
      success("AvailableClass deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <AvailableClassesContext.Provider
    value={{
      ...state,
      setAvailableClass,
      getAvailableClasses,
      saveAvailableClass,
      deleteAvailableClass,
      createAvailableClass,
      getSingleAvailableClass,
      setPropertyAvailableClass,
    }}
  >
    {children}
  </AvailableClassesContext.Provider>
);
};

import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import Schedule from "../components/schedule/Schedule";
import ClassTypeCard from "../components/classTypes/ClassTypeCard";
import { ClassTypeContext } from "../context/ClassTypesContext";
import { LocationsContext } from "../context/LocationsContext";
import { ReservationsContext } from "../context/ReservationsContext";
import { AuthContext } from "../context/AuthContext";
import { isCustomerInfoComplete } from "../utils/customer";
import { ModalContext } from "../context/ModalContext";
import CustomerCompleteInfo from "../components/customers/CustomerCompleteInfo";
import { AvailableClassesContext } from "../context/AvailableClassesContext";

const Presenciales = () => {
  const { modalComponent } = useContext(ModalContext)
  const { user, getUsuario } = useContext(AuthContext);
  const { getMyReservations } = useContext(ReservationsContext);
  const { locations, getLocations } = useContext(LocationsContext);
  const { class_types, getClassTypes } = useContext(ClassTypeContext);
  const { available_classes, getAvailableClasses } = useContext(
    AvailableClassesContext
  );

  useEffect(() => {
    getUsuario();
    getLocations();
    getClassTypes();
    //handleUserInfo();
    getMyReservations();
    getAvailableClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserInfo = () => {
    const infoComplete = isCustomerInfoComplete(user);
    if (!infoComplete) {
      modalComponent("Completa tu Información", <CustomerCompleteInfo />);
    }
  };

  const renderTipos = () => {
    if (class_types && class_types !== null) {
      return class_types.map((class_type) => (
        <ClassTypeCard key={class_type.class_type_id} class_type={class_type} />
      ));
    }
  };

  const renderAvailable = () => {
    if (user !== null && Array.isArray(available_classes)) {
      if (user.has_unlimited) {
        return "Ilimitadas";
      }
      return available_classes.length;
    }
  };

  return (
    <div className="container-fluid">
      <div className="row align-items-end border-bottom pb-3 mb-4">
        <div className="col-12 col-md-8">
          <h1>Calendario</h1>
        </div>
        <div className="col-12 col-md-4 text-right mobile-left">
          <h4 className="my-2">Disponibles: {renderAvailable()}</h4>
          <Link
            to="/mybikevibe/reservaciones"
            className="btn btn-link text-secondary px-0"
          >
            Ver Mis Reservaciones
          </Link>
        </div>
      </div>
      <Schedule locations={locations} />
      <h2 className="border-bottom pb-3 mt-5">Descubre los Tipos de Clases</h2>
      <div className="row">{renderTipos()}</div>
    </div>
  );
};

export default Presenciales;

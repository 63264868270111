import React from "react";
import { formatMonto } from "../../utils";
import moment from "moment";

const PurchaseCard = ({ purchase }) => {
  const renderTitle = () => {
    if (purchase.class_package && purchase.class_package !== null) {
      return purchase.class_package.title;
    }
  };
  const renderClasses = () => {
    if (purchase.class_package && purchase.class_package !== null) {
      return purchase.class_package.class_amount;
    }
  };

  const renderRow = (name, value) => {
    return <p className="mb-1"> <span className="bold">{name}: </span> {value}</p>
  }

  const getTotal = () => {
    return `$${formatMonto(purchase.total_payment)} MXN`;
  }

  const getExpiration = () => {
    return moment(purchase.createdAt)
    .add(purchase.expiration_days, "days")
    .format("DD MMM YYYY HH:mm");
  }


  return (
    <div className="card border px-0 py-0 shadow mb-3">
      <div className="card-header bg-light">
        <h5 className="mb-2">
          #{purchase.purchase_id} - {purchase.class_package?.title}
        </h5>
      </div>

      <div className="card-body">
        {renderRow('Clases', renderClasses())}
        {renderRow('Total', getTotal())}
        {renderRow('Fecha de Compra', moment(purchase.createdAt).format("DD MMM YYYY HH:mm"))}
        {renderRow('Duración', `${purchase.expiration_days} días`)}
        {renderRow('Expira en', getExpiration())}
      </div>

    </div>
  );
};

export default PurchaseCard;

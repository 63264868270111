import React, { useEffect, useContext } from "react";
import { CoachesContext } from "../../context/CoachesContext";
import HomeCoachCard from "./HomeCoachCard";

const HomeCoaches = () => {
  const { coaches, getHomeCoaches } = useContext(CoachesContext);

  useEffect(() => {
    getHomeCoaches();
  }, []);

  const renderCoaches = () => {
    if (Array.isArray(coaches)) {
      if (coaches.length > 0) {
        return (
          <div className="container">
            <h2>Coaches</h2>
            <div className="row">
              {coaches.map((coach) => (
                <HomeCoachCard key={coach.instructor_id} coach={coach} />
              ))}
            </div>
          </div>
        );
      }
    }
  };

  return <div className="container-fluid py-5">{renderCoaches()}</div>;
};

export default HomeCoaches;


import {
  SET_AVAILABLE_CLASS,
  CREATE_AVAILABLE_CLASS,
  AVAILABLE_CLASSS_RECEIVED,
  SET_PROPERTY_AVAILABLE_CLASS,
} from "../types/available_classes";

const schema = {

}

const AvailableClassesReducer = (state, { type, payload }) => {
  switch (type) {
    case AVAILABLE_CLASSS_RECEIVED:
      return { ...state, available_classes: payload };
    case SET_AVAILABLE_CLASS:
      return { ...state, available_class: payload };
    case CREATE_AVAILABLE_CLASS:
      return { ...state, available_class: schema };
    case SET_PROPERTY_AVAILABLE_CLASS: {
      const { key, value } = payload;
      const available_class = { ...state.available_class };
      available_class[key] = value;
      return { ...state, available_class };
    }
    default:
      return { ...state};
  }
};

export default AvailableClassesReducer;

import React, { useEffect, useContext } from "react";
import { navigate } from "@reach/router";
import PaqueteCard from "../components/paquetes/PaquetesCard";
import Footer from "../components/global/Footer";
import { PackagesContext } from "../context/PackageContext";

const Especiales = () => {
  const { paquetes, getPaquetes } = useContext(PackagesContext);

  useEffect(() => {
    getPaquetes();
  }, []);

  const toCheckout = (class_package_id) => {
    navigate(`/checkout/${class_package_id}`);
  };

  const renderPresenciales = () => {
    if (paquetes && paquetes !== null) {
      const especiales = paquetes.filter((paquete) => paquete.is_special_event);
      return especiales.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          action={() => toCheckout(paquete.class_package_id)}
        />
      ));
    }
  };

  return (
    <div id="landing">
      <div className="container px-2 p-4" id="especiales-container">
        <h2 className="mb-0">Opening Ride</h2>
        <div className="row">{renderPresenciales()}</div>
      </div>
      <Footer />
    </div>
  );
};

export default Especiales;

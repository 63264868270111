import React, { useContext } from "react";
import moment from "moment";
import { ModalContext } from "../../context/ModalContext";
import SingleClass from "../clases/SingleClass";
import { getAvailableClasses, hideModal } from "../../utils";
import CancelClass from "../clases/CancelClass";
import { AuthContext } from "../../context/AuthContext";
import { Link, navigate } from "@reach/router";
import { ReservationsContext } from "../../context/ReservationsContext";

const ScheduleClass = ({ singleClass, isHome }) => {
  const { modalComponent } = useContext(ModalContext);
  const { user, getUsuario } = useContext(AuthContext);
  const { reservations } = useContext(ReservationsContext);

  const showClassModal = () => {
    if (isHome) {
      return navigate("/entrar");
    }
    if (isReserved()) {
      modalComponent(
        "Cancelar clase",
        <CancelClass
          singleClass={singleClass}
          handleCallback={getUsuario}
        />
      );
    }
  };

  const getBookingsNumber = () => {
    if (Array.isArray(reservations)) {
      return reservations.filter(
        (reservation) =>
          reservation.customer_id === user.customer_id &&
          reservation.single_class_id === singleClass.single_class_id &&
          reservation.deletedAt === null
      ).length;
    }
  };

  const isFull = () => {
    if (
      singleClass !== null &&
      Array.isArray(singleClass.class_reservations) !== null
    ) {
      return singleClass.capacity - singleClass.class_reservations.length <= 0;
    }
  };

  const isReserved = () => {
    if (reservations && reservations !== null) {
      let class_ids = reservations
        .filter((reservation) => reservation.deletedAt === null)
        .map(({ single_class_id }) => single_class_id);
      return class_ids.includes(singleClass.single_class_id);
    }
    return false;
  };

  const reservarClase = () => {
    if (user === null && singleClass.class_package_id === null) {
      return navigate("/entrar");
    }
    if (singleClass.class_package_id !== null) {
      if (!isReserved()) {
        if (singleClass.capacity - singleClass.class_reservations.length > 0) {
          if (window.location.pathname === "/") {
            return navigate(`/especiales`);
          }
          return navigate(`/checkout/${singleClass.class_package_id}`);
        } else {
          return alert("Lo sentimos. Esta clase especial está llena.");
        }
      }
    }
    if (
      singleClass.capacity === null ||
      singleClass.class_reservations !== null
    ) {
      if (singleClass.capacity - singleClass.class_reservations.length > 0) {
        let available_classes = getAvailableClasses(user);
        if (isNaN(available_classes) || available_classes > 0) {
          modalComponent(
            "Reservar Clase",
            <SingleClass
              clase={singleClass}
              hideModal={hideModal}
              isGuest={isReserved()}
            />
          );
        } else {
          modalComponent(
            "Créditos Agotados",
            <div className="container-fluid px-0">
              <p>
                Lo sentimos. Has agotado todos tus créditos para reservar Clases
                Presenciales. Puedes comprar más en Paquetes.
              </p>
              <p className="bold">
                Después de comprar, debes regresar a reservar tu clase.
              </p>
              <Link to="/mybikevibe/shop" className="btn btn-success">
                Ir a Paquetes
              </Link>
            </div>
          );
        }
      }
    }
  };

  const renderInstructors = () => {
    if (singleClass.class_instructors.length === 1) {
      const instructor = singleClass.class_instructors[0].instructor;
      if (instructor !== null) {
        return instructor.nick_name !== null
          ? instructor.nick_name
          : instructor.name;
      }
    } else {
      return singleClass.class_instructors.map(({ instructor }, index) => (
        <span key={index} className="d-block">
          {instructor.nick_name !== null
            ? instructor.nick_name
            : instructor.name}
        </span>
      ));
    }
  };

  const renderRemainingSpots = () => {
    const diff = singleClass.capacity - singleClass.class_reservations.length;
    if (diff <= 3 && diff > 0) {
      return <p className="mb-0 badge badge-pill bg-danger">{diff} lugares</p>;
    }
  };

  const renderButtons = () => {
    if (!isReserved()) {
      return (
        <button
          className="btn btn-dark d-block text-center m-auto btn-sm my-2"
          onClick={reservarClase}
        >
          Reservar
        </button>
      );
    }
    let bookingNumber = getBookingsNumber();
    return (
      <div>
        <p className="mb-1 bold">{bookingNumber} lugares</p>
        <button className="btn btn-dark btn-sm m-1" onClick={reservarClase}>
          +1 <i className="fa fa-user"></i>
        </button>
        {bookingNumber === 1 && (
          <button
            className="btn btn-outline-light btn-sm m-1"
            onClick={showClassModal}
          >
            Cancelar
          </button>
        )}
      </div>
    );
  };

  return (
    <div
      className={`schedule-class my-2 ${
        singleClass.class_package_id !== null
          ? "bg-dark text-accent"
          : "text-white"
      } ${isReserved() ? "bg-success" : isFull() ? "bg-secondary" : ""}`}
      style={{ backgroundColor: singleClass.class_type.color }}
    >
      <p className="mb-1 bold">
        <i className={singleClass.icon} /> {singleClass.class_type.name}
      </p>
      <p className="mb-1 small">{singleClass.description}</p>
      <p className="mb-0">{renderInstructors()}</p>
      <p className="mb-1">
        <i className="far fa-clock"></i>{" "}
        {moment(singleClass.class_date).utc().format("HH:mm")}
      </p>
      {renderButtons()}
      {renderRemainingSpots()}
    </div>
  );
};

export default ScheduleClass;

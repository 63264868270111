import React from "react";
import {
  findExpirationRenewalDate,
  formatMonto,
  S3_ENDPOINT,
} from "../../utils";
import moment from "moment";

const PurchaseRow = ({ purchase, cancelSubscription }) => {
  const validClassPackage = () => {
    return purchase.class_package && purchase.class_package !== null;
  };

  const renderActions = () => {
    if (validClassPackage()) {
      return (
        <td>
          {renderFile()}
          {renderLink()}
          {renderCancel()}
        </td>
      );
    }
    return <td>{renderCancel()}</td>;
  };

  const renderFile = () => {
    if (
      purchase.class_package.downloadable &&
      purchase.class_package.downloadable !== null
    ) {
      return (
        <a
          href={`${S3_ENDPOINT}/${purchase.class_package.downloadable.name}.${purchase.class_package.downloadable.type}`}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="fa fa-file"></i>
        </a>
      );
    }
  };

  const renderLink = () => {
    if (
      purchase.class_package.file_url &&
      purchase.class_package.file_url !== null
    ) {
      return (
        <a
          target="_blank"
          href={purchase.class_package.file_url}
          className="btn btn-sm btn-outline-secondary"
        >
          <i className="fa fa-link"></i>
        </a>
      );
    }
  };

  const renderCancel = () => {
    if (purchase.subscription_id !== null) {
      if (purchase.status === "active") {
        return (
          <button
            className="btn btn-outline-danger btn-sm"
            onClick={() => cancelSubscription(purchase)}
          >
            <i className="fa fa-times"></i>
          </button>
        );
      }
    }
  };

  const renderClassPackage = () => {
    if (validClassPackage()) {
      return purchase.class_package.title;
    }
  };

  const renderClasses = () => {
    if (purchase.class_package && purchase.class_package !== null) {
      return purchase.class_package.class_amount;
    }
  };

  return (
    <tr>
      <td className="">#{purchase.purchase_id}</td>
      <td className="">{renderClassPackage()}</td>
      <td className="">{renderClasses()}</td>
      <td className="">
        {"$"}
        {formatMonto(purchase.total_payment)} {"MXN"}
      </td>
      <td className="">
        {moment(purchase.createdAt).format("DD MMM YYYY HH:mm")}
      </td>
      <td className="">
        {purchase.expiration_days} días
      </td>
      <td>
        {moment(purchase.createdAt)
          .add(purchase.expiration_days, "days")
          .format("DD MMM YYYY HH:mm")}
      </td>
    </tr>
  );
};

export default PurchaseRow;


import React, { createContext, useReducer, useContext } from 'react';
import PagesService from '../services/PagesService';
import PagesReducer from '../reducers/PagesReducer';
import {
  PAGES_RECEIVED,
  SET_PAGE,
  CREATE_PAGE,
  SET_PROPERTY_PAGE,
} from "../types/Pages";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  pages: null,
  page: null,
};

export const PagesContext = createContext(initialState);

export const PagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PagesReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getPages = () => {
    PagesService.getPages()
      .then((response) => {
        const { Pages } = response.data;
        dispatch({ type: PAGES_RECEIVED, payload: Pages });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getPageBySlug = (slug) => {
    PagesService.getPageBySlug(slug)
      .then(res => {
        const { page } = res.data;
        dispatch({ type: SET_PAGE, payload: page });
      })
      .catch(err => {
        alert(err);
      })
  }

  const getSinglePage = (Page_id) => {
    PagesService.getSinglePage(Page_id)
      .then((response) => {
        const { Page } = response.data;
        dispatch({ type: SET_PAGE, payload: Page });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setPage = (Page) => {
    dispatch({ type: SET_PAGE, payload: Page });
  };

  const createPage = () => {
    dispatch({ type: CREATE_PAGE });
  };

  const setPropertyPage = (key, value) => {
    dispatch({ type: SET_PROPERTY_PAGE, payload: { key, value } });
  };

  const savePage = (Page, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = PagesService.putPage;
      if(isNaN(parseInt(Page.Page_id))) {
        service = PagesService.postPage;
      }
      service(Page).then(() => {
        success("Page saved.");
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deletePage = (Page_id, callback) => {
     dispatch({ type: SHOW_SPINNER });
    PagesService.deletePage(Page_id).then(() => {
      success("Page deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

 

  return (
    <PagesContext.Provider
    value={{
      ...state,
      setPage,
      getPages,
      savePage,
      deletePage,
      createPage,
      getSinglePage,
      getPageBySlug,
      setPropertyPage,

    }}
  >
    {children}
  </PagesContext.Provider>
);
};

import React from "react";
import ScheduleDay from "./ScheduleDay";
import utils from "./utils";

const ScheduleMobile = ({ weekClasses }) => {
  const renderDays = () => {
    if (Array.isArray(weekClasses)) {
      let has_classes = utils.hasClases(weekClasses);
      if (!has_classes) {
        return (
          <div className="row">
            <p className="px-0 mb-0 text-center">No hay clases programadas.</p>
          </div>
        );
      }
      return weekClasses.map((day) => (
        <ScheduleDay day={day} key={day.date} clases={day.details} />
      ));
    }
  };

  return (
    <div className="container-fluid schedule-mobile-container">
      <div className="schedule-mobile-wrapper">
        <div className="row">{renderDays()}</div>
      </div>
    </div>
  );
};

export default ScheduleMobile;

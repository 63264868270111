
import api from './api';

const route = '/Pages';

const PagesService = {
  getPages: () => api.get(route),
  getPageBySlug: (slug) => api.get(`${route}/${slug}`),
  getSinglePage: (page_id) => api.get(`${route}/${page_id}`),
  postPage: (Page) => api.post(route, { ...Page}),
  putPage: (Page) => api.put(route, { ...Page}),
  deletePage: (Page) => api.delete(`${route}/${Page}`),
};

export default PagesService;

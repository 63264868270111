import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { ReservationsContext } from "../context/ReservationsContext";
import FilaReservaciones from "../components/reservaciones/FilaReservaciones";
import HeaderRow from "../components/global/HeaderRow";
import { getAvailableClasses } from "../utils";

const MisReservaciones = () => {
  const { user } = useContext(AuthContext);
  const { reservations, getMyReservations } = useContext(ReservationsContext);

  const [filtered, setFiltered] = useState(true);

  useEffect(() => {
    getMyReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderReservaciones = () => {
    if (reservations && reservations !== null) {
      if (reservations.length === 0)
        return (
          <div className="container-fluid px-0">
            <p>Aún no has reservado clases.</p>
            <Link to="/mybikevibe" className="btn btn-accent bold">
              Ir a Reservar
            </Link>
          </div>
        );
      let reservationsRender = reservations;
      if (filtered) {
        reservationsRender = reservationsRender.filter((reservation) =>
          moment(reservation.class_date)
            .utc()
            .isAfter(moment().utc().subtract(1, "days"))
        );
      }
      return (
        <div>
          <HeaderRow headers={["Clase", "Coach", "Dia/Hora", "Lugar", "Acciones"]} />
          {reservationsRender.map((reservation) => (
            <FilaReservaciones
              key={reservation.class_reservation_id}
              reservacion={reservation}
            />
          ))}
        </div>
      );
    }
    return <div className="spinner-border"></div>;
  };
  return (
    <div className="container-fluid">
      <div className="row  mb-3">
        <div className="col-12 col-md-6 mb-2">
          <h1>Mis Reservaciones</h1>
          <p className="mb-0">
            Sólo puedes cancelar clases con 6 horas de anticipación
          </p>
        </div>
        <div className="col-12 col-md-6 mb-2 text-end mobile-left">
          <h4>Restantes: {getAvailableClasses(user)}</h4>
          <button
            className="btn btn-outline-dark me-3"
            onClick={() => setFiltered(!filtered)}
          >
            {filtered ? "Mostrar" : "Ocultar"} Anteriores
          </button>
          <Link to="/mybikevibe/shop" className="btn btn-accent">
            Comprar Clases
          </Link>
        </div>
      </div>
      {renderReservaciones()}
    </div>
  );
};

export default MisReservaciones;

import React from "react";
import Page from "../../components/global/Page";

const Terms = () => {
  return (
    <Page title="Términos y Condiciones">
      <div style={{ overflowY: "auto", minHeight: "100vh" }}>
        <h3>Resumen</h3>
        <p>
          Este sitio web con dominio www.bikevibemx.com es operado por BIKE
          VIBE. A lo largo de la presente declaración de TÉRMINOS DEL SERVICIO
          se mencionará "nosotros" y "nuestro" refiriéndose a BIKE VIBE, quien
          ofrece este sitio web, incluyendo toda la información, herramientas y
          servicios disponibles para el usuario, condicionando la aceptación de
          todos los términos, condiciones, políticas y avisos aquí establecidos.
        </p>
        <p>
          Al visitar nuestro sitio y/o comprar un artículo con nosotros, el
          usuario / cliente participa en nuestro "SERVICIO" y acepta estar
          sujeto a nuestros “TÉRMINOS DEL SERVICIO”, incluidos términos y
          condiciones adicionales, así como las políticas disponibles mediante
          cualquier hipervínculo dentro de nuestro sitio web. Estos TÉRMINOS DEL
          SERVICIO se aplican a todos los usuarios del sitio, incluidos sin
          limitación, usuarios que son navegadores, vendedores, proveedores,
          clientes, comerciantes y/o contribuidores de contenido.
        </p>
        <p>
          Por favor, revise cuidadosamente la presente información antes de
          navegar en nuestro sitio web. Al acceder o utilizar cualquier parte de
          nuestro sitio web, el usuario acepta estar sujeto a los TÉRMINOS DEL
          SERVICIO. Si no está de acuerdo con todos los términos y condiciones
          expresados en nuestro sitio web, entonces no deberá acceder al sitio,
          ni utilizar ningún servicio.
        </p>
        <p>
          Las nuevas características o herramientas que se agreguen al sitio web
          actual también estarán sujetas a los TÉRMINOS DEL SERVICIO. Siempre
          que el usuario lo desee, podrá revisar la versión actualizada en esta
          página. Nos reservamos el derecho de actualizar, cambiar o reemplazar
          cualquier parte de nuestros términos, condiciones o políticas mediante
          la publicación de actualizaciones y/o cambios en nuestro sitio web. Es
          responsabilidad del usuario revisar esta página periódicamente para
          ver si hay cambios. Su uso continuo o acceso al sitio web después de
          la publicación de cualquier cambio constituye la aceptación de los
          mismos.
        </p>
        <h3>1. TÉRMINOS DE LA TIENDA ONLINE</h3>
        <p>
          Al aceptar los TÉRMINOS DEL SERVICIO, el usuario declara que es mayor
          de edad, o bien si es menor de edad deberá realizar las compras bajo
          supervisión de una persona mayor de edad, quien otorga consentimiento
          y se hace responsable de las transacciones que se realicen en línea.
        </p>
        <p>
          Los usuarios no pueden utilizar nuestros productos y servicios para
          ningún propósito ilegal o no autorizado, ni puede violar cualquier ley
          en su jurisdicción (incluyendo las leyes de derecho de autor). El
          usuario no podrá transmitir ningún virus, malware o código de
          naturaleza destructiva que pueda afectar la operación de nuestro sitio
          web. Una infracción o violación de cualquiera de nuestros términos,
          resultará en la terminación inmediata de sus servicios, sin derecho a
          réplica.
        </p>
        <h3>2. CONDICIONES GENERALES</h3>
        <p>
          Nos reservamos el derecho de rechazar el servicio a cualquier persona,
          por cualquier motivo, en cualquier momento. El usuario entiende que
          nosotros (a) podemos almacenar, utilizar o divulgar sus datos (sin
          incluir información de tarjeta de crédito, débito u otro método de
          pago) sin cifrar la información, para diversos fines, a través de
          distintas redes; (b) incluyendo cambios para adaptarse a los
          requisitos técnicos de conexión a redes o dispositivos. La información
          de la tarjeta de crédito siempre se cifra durante la transferencia a
          través de las redes y es eliminada una vez que se completa dicha
          transacción.
        </p>
        <p>
          El usuario se compromete a no reproducir, duplicar, copiar, vender,
          revender o explotar ninguna parte, producto, contacto o acceso a
          nuestro servicio, sin el permiso expreso por escrito de parte de
          nosotros.
        </p>
        <p>
          Los encabezados utilizados en este acuerdo se incluyen sólo por
          conveniencia y no limitarán ni afectarán de ninguna otra manera estos
          términos.
        </p>
        <h3>
          3. EXACTITUD, INTEGRIDAD Y ACTUALIZACIÓN DE LA INFORMACIÓN (CONTENIDOS
          DEL SITIO WEB)
        </h3>
        <p>
          Nosotros no somos responsables si la información disponible en nuestro
          sitio web no es exacta, completa o actualizada. El material de este
          sitio se proporciona únicamente para información general. Nos
          reservamos el derecho de modificar el contenido de este sitio en
          cualquier momento. El usuario acepta que es su responsabilidad
          supervisar cualquier cambio que se realice en nuestro sitio.
        </p>
        <h3>4. PROPIEDAD INTELECTUAL</h3>
        <p>
          BIKE VIBE cuenta con sus registros marcarios ante el Instituto
          Mexicano de Propiedad Industrial, así que queda prohibido el uso
          indebido de la marca BIKE VIBE bajo ningún tipo de circunstancia. En
          cuanto a las fotografías e imágenes que se utilicen en la página web
          de BIKE VIBE, se declara en la presente cláusula que los derechos de
          autor pertenecen a BIKE VIBE y se prohíbe hacer un uso con las mismas.
          Sus logotipos y todo el material que aparece en la página de Internet
          referida son marcas, nombres de dominio y/o nombres comerciales
          propiedad de sus respectivos titulares protegidos por los tratados
          internacionales y leyes aplicables en materia de propiedad intelectual
          y derechos de autor.
        </p>
        <h3>5. MODIFICACIONES AL SERVICIO Y PRECIOS</h3>
        <p>
          Los precios de los productos dentro de nuestro sitio web, están
          sujetos a cambios sin previo aviso. Nos reservamos el derecho de
          modificar, cancelar o descontinuar cualquier producto y/o servicio
          (también cualquier parte o contenido de este) sin previo aviso y en
          cualquier momento. No somos responsables ante el usuario o terceros
          por cualquier modificación, cambio de precio, suspensión o
          interrupción del servicio.
        </p>
        <h3>6. ACCESO INTERNACIONAL</h3>
        <p>
          A este sitio se puede acceder desde países distintos de los Estados
          Unidos Mexicanos. Este sitio puede contener productos o referencias a
          productos que no están disponibles fuera de los Estados Unidos
          Mexicanos. Cualquiera de esas referencias no implica que estos
          productos o servicios estarán disponibles fuera de los Estados Unidos
          Mexicanos. Si el usuario accede y usa este sitio fuera de los Estados
          Unidos Mexicanos él es responsable de cumplir con las leyes y
          reglamentos locales.
        </p>
        <h3>7. PRODUCTOS O SERVICIOS</h3>
        <p>
          Estos productos o servicios pueden tener cantidades limitadas y no
          están sujetos a devolución. Hacemos todo lo posible para mostrar con
          la mayor exactitud los colores e imágenes de los productos en nuestro
          sitio web, sin embargo, no podemos garantizar que la visualización de
          los colores, formas o la calidad de las fotografías sea exacta en
          todos los dispositivos.
        </p>
        <p>
          Nos reservamos el derecho de limitar las ventas de nuestros productos
          a cualquier persona, región geográfica o jurisdicción. También, nos
          reservamos el derecho de limitar las cantidades y/o suspender la venta
          de cualquier producto o servicio, y podremos ejercer estos derechos en
          cualquier momento. Todas las descripciones, precios y ofertas sobre
          los productos en nuestro sitio web, están sujetas a cambios sin previo
          aviso. No podemos garantizar que la calidad de los productos,
          servicios o cualquier material comprado en nuestro sitio web o
          mensajes, cumpla con las expectativas exactas de cada cliente, de modo
          que, si se llega a presentar algún error en nuestros productos y/o
          servicio, será evaluado y resuelto por nuestro equipo en BIKE VIBE.
        </p>
        <p>
          Se le recomienda al cliente llegar 15 minutos antes de clase para
          poder seguir el protocolo COVID, dar instrucciones y acomodarse en su
          lugar.
        </p>
        <p>
          Se da una tolerancia de 5 minutos para que llegue el cliente, en caso
          de llegar después de los 5 minutos no podrá entrar por respeto a los
          demás que llegaron a tiempo y para evitar que se interrumpa la
          experiencia que se ofrece en la clase.
        </p>
        <h3>8. VIGENCIA DE CLASES Y CANCELACIONES</h3>
        <p>
          Las clases al momento de ser compradas tienen un límite de cierta
          vigencia de días especificada en cada paquete para usarse sin derecho
          a reclamo.
        </p>
        <p>
          Al momento de pagar separas tu lugar y se podrá cancelar la clase 6
          horas antes como máximo con derecho a tomarla después respetando la
          vigencia correspondiente.
        </p>
        <h3>9. EXACTITUD DE LA INFORMACIÓN DE FACTURACIÓN Y CUENTA</h3>
        <p>
          Nos reservamos el derecho de rechazar cualquier pedido que el usuario
          realice con nosotros. Podemos, a nuestra discreción, limitar o
          cancelar las cantidades compradas por persona o pedido. Estas
          restricciones pueden incluir pedidos realizados bajo la misma cuenta
          de cliente, la misma tarjeta de crédito, débito u otro método de pago,
          y/o pedidos que usen la misma dirección de facturación y/o envío.
        </p>
        <p>
          En el caso de que hagamos un cambio o cancelemos un pedido,
          intentaremos notificarlo al usuario vía correo electrónico o por
          teléfono. Nos reservamos el derecho de limitar o prohibir órdenes que,
          a nuestro juicio, parecen ser colocadas por distribuidores o
          revendedores.
        </p>
        <p>
          El usuario acepta que al realizar una transacción o compra dentro de
          nuestro sitio web, está proporcionando información actualizada,
          completa y precisa. No nos hacemos responsables por compras o envíos
          incorrectos, derivados de errores cometidos por el usuario al
          proporcionar datos como dirección de envío, correo electrónico,
          teléfono, números de tarjeta de crédito, fechas de vencimiento o de
          cualquier otro dato que sea solicitado.
        </p>
        <h3>10. HERRAMIENTAS OPCIONALES</h3>
        <p>
          En ocasiones, podemos proporcionar al usuario acceso a herramientas de
          terceros sobre las que no tenemos control. El usuario reconoce y
          acepta que nuestro sitio web utiliza herramientas de terceros, sin
          ninguna garantía, representación, condiciones de ningún tipo y sin
          ningún endoso. No tendremos ninguna responsabilidad derivada o
          relacionada con el uso de herramientas de terceros. Cualquier uso de
          dichas herramientas, es bajo el propio riesgo y discreción del
          usuario, y este debe asegurarse de estar familiarizado con los
          términos proporcionados por los terceros.
        </p>
        <p>
          Podemos ofrecer nuevos servicios y/o características a través del
          sitio web (incluyendo el lanzamiento de nuevos productos, herramientas
          y recursos) en cualquier momento, los cuales también estarán sujetos a
          nuestros TÉRMINOS DEL SERVICIO.
        </p>
        <h3>11. ENLACES DE TERCEROS</h3>
        <p>
          Ciertos contenidos o productos disponibles a través de nuestro sitio
          web pueden incluir imágenes, descripciones, recursos o hipervínculos
          de terceros que pueden dirigirlo a sitios web que no están afiliados
          con nosotros. No somos responsables de examinar o evaluar la exactitud
          y veracidad del contenido de terceros, por lo que no garantizamos y no
          asumiremos ninguna responsabilidad por ningún otro material, producto
          o servicio de terceros.
        </p>
        <h3>12. COMENTARIOS DE LOS USUARIOS</h3>
        <p>
          Si, a petición nuestra, el usuario envía comentarios específicos (por
          ejemplo, satisfacción en el servicio), este acepta que podemos, en
          cualquier momento, sin restricciones, editar, copiar, publicar,
          distribuir, traducir y de otra manera utilizar cualquier comentario
          que nos envíe, en cualquier medio. Somos y no estaremos obligados (1)
          a mantener comentarios confidenciales; (2) a pagar una compensación
          por cualquier comentario; o (3) responder a cualquier comentario.
        </p>
        <h3>13. INFORMACIÓN PERSONAL</h3>
        <p>
          La información personal del usuario es recolectada, almacenada,
          utilizada y divulgada por nosotros bajo los estándares de seguridad
          más altos. Los datos proporcionados por el usuario se rigen por
          nuestro AVISO DE PRIVACIDAD, el cual está disponible en nuestro sitio
          web y podrá ser consultado por los usuarios en cualquier momento.
        </p>
        <h3>14. ERRORES, INEXACTITUDES Y OMISIONES</h3>
        <p>
          Ocasionalmente, puede haber información en nuestro sitio web que
          contenga errores tipográficos, inexactitudes u omisiones que puedan
          estar relacionadas con la descripción de un producto, precio,
          promoción, gastos de envío, tiempos de entrega y disponibilidad. Nos
          reservamos el derecho de actualizar dicha información o cancelar
          órdenes incorrectas, derivadas de información inexacta, en cualquier
          momento y sin previo aviso (incluso después de haber enviado un
          pedido).
        </p>
        <h3>15. USOS PROHIBIDOS</h3>
        <p>
          Además de otras prohibiciones establecidas en los TÉRMINOS DEL
          SERVICIO, está prohibido utilizar el sitio o su contenido: (a) para
          cualquier propósito ilícito; b) solicitar a otros que realicen o
          participen en actos ilícitos; (c) violar cualquier reglamento o ley
          local, internacional, federal o estatal; (d) infringir o violar
          nuestros derechos de propiedad intelectual o los derechos de propiedad
          intelectual de terceros; (e) acosar, abusar, insultar, dañar, difamar,
          calumniar, desacreditar, intimidar o discriminar por motivos de
          género, orientación sexual, religión, origen étnico, raza, edad,
          nacionalidad o discapacidad; f) presentar información falsa o
          engañosa; (g) cargar o transmitir virus o cualquier otro código de
          tipo malicioso; (h) recopilar o rastrear la información personal de
          otros; (i) realizar spam, phishing o cualquier otra irregularidad y/o
          fraude electrónico; (j) para cualquier propósito obsceno o inmoral;
          (k) interferir o eludir las características de seguridad del Servicio.
          Nos reservamos el derecho de terminar el uso del servicio a cualquier
          usuario que viole los usos prohibidos.
        </p>
        <h3>16. MÉTODOS DE COMPRA Y PAGO</h3>
        <p>
          Los métodos de pago serán exclusivamente a través de esta página
          www.bikevibemx.com y solo será de esta manera. El cliente debe tomar
          en cuenta que no se acepta dinero en la sucursal.
        </p>
        <p>
          El pago de los productos y servicios puede realizarse por medio de los
          siguientes métodos de pago: PayPal y Transferencias.
        </p>
        <p>
          Al realizar sus pagos se sujeta y acepta sin reservas a los Términos y
          Condiciones y Aviso de Privacidad de los proveedores de servicios
          relacionados con dichos pagos.
        </p>
        <h3>17. RIDES Y EVENTOS ESPECIALES</h3>
        <p>
          Cualquier festejo de cliente deberá ser notificado al encargado/a y
          tendrá un costo adicional y deberá llevarse a cabo fuera de los
          horarios establecidos en la página{" "}
        </p>
        <h3>18. RENUNCIA DE GARANTÍAS Y LIMITACIÓN DE RESPONSABILIDADES</h3>
        <p>
          No podemos garantizar que el uso de nuestro servicio será
          ininterrumpido, libre de errores o que su resultado sea completamente
          preciso y confiable. El usuario acepta que puede haber ocasiones, en
          donde deberemos retirar el servicio por periodos de tiempo indefinidos
          o cancelar el servicio en cualquier momento, sin previo aviso. El
          usuario acepta expresamente que el uso de nuestro servicio es bajo su
          propia jurisdicción; este podrá adquirir y hacer uso de nuestros
          productos y servicios, siempre y cuando estén disponibles, sin ninguna
          representación, garantía o condición de ningún tipo, expresa o
          implícita, incluyendo calidad comercial, aptitud para un propósito
          particular y/o durabilidad.
        </p>
        <p>
          En ningún caso BIKE VIBE, sus directores, administradores,
          funcionarios, empleados, afiliados o proveedores serán responsables de
          cualquier pérdida, reclamación, daño directo, indirecto, incidental,
          pérdida de datos, costos de reemplazo, o cualquier daño similar, ya
          sea por contrato, agravio (incluyendo negligencia), responsabilidad
          estricta o de otro tipo; o bien de cualquier otra reclamación
          relacionada a nuestros productos y servicios, incluyendo, cualquier
          error u omisión en el contenido, pérdidas o daños de cualquier tipo
          incurrido como resultado del uso de nuestros productos y servicios.
        </p>
        <h3>19. INDEMNIZACIÓN</h3>
        <p>
          El usuario se compromete a indemnizar, defender y eximir a BIKE VIBE,
          sus directores, administradores, funcionarios, empleados, afiliados o
          proveedores de cualquier reclamación o demanda, incluyendo honorarios
          de abogados, hechos por cualquier tercero, derivado del incumplimiento
          de los TÉRMINOS DEL SERVICIO, de otras políticas, o debida violación
          de cualquier ley.
        </p>
        <h3>20. DIVISIBILIDAD</h3>
        <p>
          En el caso que se determine que alguna disposición de estos TÉRMINOS
          DEL SERVICIO es nula o inexigible, será aplicable toda la extensión
          permitida por la ley correspondiente. Dicha determinación no afectará
          la validez y aplicabilidad de los términos y disposiciones restantes.
        </p>
        <h3>21. TERMINACIÓN</h3>
        <p>
          Estos TÉRMINOS DEL SERVICIO son efectivos a menos que exista una
          suspensión del servicio por parte de nosotros, o bien que el usuario
          no acepte las políticas de nuestro sitio web, en cuyo caso no podrá
          hacer uso de nuestros servicios. Si bajo nuestro criterio, el usuario
          incumple con cualquier término o disposición, podremos terminar el
          acuerdo y suspender el servicio sin derecho a réplica, en cualquier
          momento y sin previo aviso.
        </p>
        <h3>22. ACUERDO COMPLETO</h3>
        <p>
          El incumplimiento por parte de nosotros de cualquier término o
          disposición de los TÉRMINOS DEL SERVICIO no constituirá la anulación
          de los términos para el resto de los usuarios de nuestro sitio web.
          Las políticas, términos y reglas de operación publicadas en nuestro
          sitio web, constituyen el acuerdo completo y el entendimiento entre el
          usuario/cliente y BIKE VIBE, reemplazando cualquier acuerdo previo de
          palabra, ya sea oral o escrito, incluyendo, pero no limitado a,
          cualquier versión anterior de los TÉRMINOS DEL SERVICIO. Cualquier
          ambigüedad en la interpretación de estos TÉRMINOS DE SERVICIO no se
          interpretará en contra de la parte redactora.
        </p>
        <h3>23. LEY APLICABLE</h3>
        <p>
          Estos TÉRMINOS DEL SERVICIO y cualquier acuerdo separado por el cual
          le prestemos servicios, serán regidos e interpretados de acuerdo con
          las leyes del estado de Nuevo León.
        </p>
        <h3>24. CAMBIOS A LOS TÉRMINOS DE SERVICIO</h3>
        <p>
          Puede revisar la versión más reciente de los TÉRMINOS DEL SERVICIO en
          cualquier momento en esta página. Nos reservamos el derecho, a nuestra
          sola discreción, de actualizar, cambiar o reemplazar cualquier parte
          de los TÉRMINOS DEL SERVICIO mediante la publicación de
          actualizaciones y cambios en nuestro sitio web. Es responsabilidad del
          usuario/cliente revisar periódicamente nuestro sitio web para ver si
          hay cambios. Su uso continuo o acceso a nuestro sitio web, constituye
          la aceptación de dichos cambios.
        </p>
        <h3>25. DEVOLUCIONES Y CAMBIOS</h3>
        <p>
          Los productos y servicios no están sujetos a devoluciones, debido a
          que existen clases de prueba donde permiten al cliente experimentar.
        </p>
        <p>Los servicios no son transferibles a otra persona.</p>
        <h3>26. INFORMACIÓN DE CONTACTO</h3>
        <p>
          Cualquier persona que tenga dudas, sugerencias o comentarios acerca de
          los TÉRMINOS DE SERVICIO podrá contactarnos mediante los siguientes
          medios:
        </p>
        <h4>BIKE VIBE</h4>
        <p>Tel: 8180908315</p>
        <p>Correo: bikevibemty@gmail.com</p>
        <p>
          Plaza Forum Leones.- Av. Paseo de los Leones #9203, Colonia Espacio
          Cumbres Privada 4 y 5, Código Postal 64346, Monterrey, Nuevo León.
        </p>
      </div>
    </Page>
  );
};

export default Terms;

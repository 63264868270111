import React  from "react";
import { Link } from "@reach/router";

const AdditionalInfo = ({}) => {
  return (
    <div className="card px-0 no-scale shadow-sm">
      <div className="container-fluid">
        <h4 className="pb-2 border-bottom">Información Adicional</h4>

        <div className="row">
          <div className="col-12">
            <Link className="btn w-100 text-start px-1" to={"/privacidad"}>
              <i className="fas fa-info me-2"></i> Aviso de Privacidad
            </Link>
          </div>
          <div className="col-12">
            <Link
              className="btn w-100 text-start px-1"
              to={"/terminos-y-condiciones"}
            >
              <i className="fas fa-info me-2"></i> Terminos y Condiciones
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfo;

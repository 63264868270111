import React, { createContext, useContext, useReducer } from "react";
import ReservationsReducer from "../reducers/ReservationsReducer";
import ReservationsService from "../services/ReservationsService";
import {
  RESERVATIONS_RECIBIDAS,
  SHOW_SPINNER,
  HIDE_SPINNER,
  SET_UPDATED,
} from "../types";
import { ModalContext } from "./ModalContext";

const initialState = {
  reservations: null,
  reservation: null,
  updated: false,
  spinner: false,
};

export const ReservationsContext = createContext(initialState);

export const ReservationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReservationsReducer, initialState);

  const { success, alert, clearModal } = useContext(ModalContext);

  const getMyReservations = () => {
    ReservationsService.getMyReservations().then((res) => {
      const { class_reservations } = res.data;
      dispatch({ type: RESERVATIONS_RECIBIDAS, payload: class_reservations });
    });
  };

  const cancelReservacion = (class_reservation_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    ReservationsService.cancelReservation(class_reservation_id)
      .then(() => {
        clearModal();
        getMyReservations();
        dispatch({ type: HIDE_SPINNER });
        success("¡Reservación cancelada con éxito!");
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            clearModal();
            alert(error);
          }
        }
        if (typeof callback === "function") {
          callback();
        }
      });
  };

  const postReservacion = (clase, callback) => {
    let service;
    if (clase.class_reservation_id) {
      service = ReservationsService.putReservation(clase);
    } else {
      service = ReservationsService.postReservation(clase);
    }
    dispatch({ type: SHOW_SPINNER });
    service
      .then(() => {
        clearModal();
        getMyReservations();
        dispatch({ type: HIDE_SPINNER });
        success("¡Reservación guardada con éxito!");
        dispatch({ type: SET_UPDATED });
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        if (error.response) {
          if (error.response.status === 412) {
            return alert(error);
          } else if (error.response.status === 409) {
            return alert(
              "Lo sentimos. Este lugar ya ha sido ocupado, refrezca la página y vuelve a intentarlo."
            );
          }
        }
        if (typeof callback === "function") {
          callback();
        }
      });
  };

  const postAttend = (class_reservation_id, attend) => {
    ReservationsService.postAttend(class_reservation_id, attend).then(() => {
      success(attend ? "Asistencia registrada." : "Asistencia cancelada");
    });
  };

  const updateGuestName = (class_reservation_id, name) => {
    ReservationsService.updateGuestName(class_reservation_id, name).then(() => {
      success("¡Nombre actualizado!");
    });
  };

  const saveRating = (rating_data) => {
    ReservationsService.postRating(rating_data)
      .then(() => {
        success("Clase calificada");
        clearModal();

        getMyReservations();
      })
      .catch((error) => {
        alert(error);
        clearModal();
      });
  };

  const eliminarReservacion = (class_reservation_id) => {
    ReservationsService.deleteAsistente(class_reservation_id).then(() => {
      success("¡Reservacion eliminada!");
      clearModal();
    });
  };

  const updateReservacion = (class_reservation) => {
    ReservationsService.putReservacion(class_reservation).then(() => {
      success("Reservación actualizada.");
      getMyReservations();
      clearModal();
    });
  };

  return (
    <ReservationsContext.Provider
      value={{
        ...state,
        postAttend,
        postReservacion,
        updateGuestName,
        updateReservacion,
        saveRating,
        cancelReservacion,
        getMyReservations,
        eliminarReservacion,
      }}
    >
      {children}
    </ReservationsContext.Provider>
  );
};

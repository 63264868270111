import React, { useContext } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { AuthContext } from "../../context/AuthContext";

const CheckoutDiscount = ({ class_package_id }) => {
  const { user } = useContext(AuthContext);

  const { discountCode, setDiscountCode, spinnerDescuento, validarDescuento } =
    useContext(CheckoutContext);

  const handleSubmitDescuento = (e) => {
    e.preventDefault();
    validarDescuento(discountCode, class_package_id);
  };

  const renderDescuento = () => {
    if (user !== null) {
      return (
        <form className="card no-scale" onSubmit={handleSubmitDescuento}>
          <label className="bold">Código de Descuento</label>
          <div className="row">
            <div className="col-md-6 col-12 my-2">
              <input
                type="text"
                className="form-control"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
              />
            </div>
            <div className="col-md-6 col-12 my-2">
              <button
                type="submit"
                className="btn w-100 btn-outline-primary"
                disabled={spinnerDescuento}
              >
                {spinnerDescuento ? (
                  <div className="spinner-border"></div>
                ) : (
                  "Aplicar"
                )}
              </button>
            </div>
          </div>
        </form>
      );
    }
  };

  return <div>{renderDescuento()}</div>;
};

export default CheckoutDiscount;

import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import MapaLugares from "./MapaLugares";
import SingleCoach from "../coaches/SingleCoach";
import { ReservationsContext } from "../../context/ReservationsContext";

const SingleClass = ({ clase, isGuest, hideModal }) => {
  const [place, setPlace] = useState(null);
  const [guest, setGuest] = useState("");
  const [error, setError] = useState(null);

  const { spinner, postReservacion } = useContext(ReservationsContext);

  useEffect(() => {
    if (guest !== "" && error !== null) {
      setError(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guest]);

  const handleSubmit = () => {
    if (isGuest) {
      if (guest === "") {
        return setError("Debes ingresar un nombre");
      }
    }
    if (place === null) {
      return setError("Debes elegir un lugar");
    }
    postReservacion({
      single_class_id: clase.single_class_id,
      class_date: clase.class_date,
      guest: isGuest ? guest : null,
      spot: place,
    });
  };

  const renderCoaches = () => {
    if (Array.isArray(clase.class_instructors)) {
      return clase.class_instructors.map((class_instructor) => (
        <SingleCoach
          key={class_instructor.instructor.instructor_id}
          coach={class_instructor.instructor}
        />
      ));
    }
  };

  return (
    <div className="container-fluid px-0">
      <h4 className="mb-1">
        <i className={clase.class_type.icon} /> {clase.class_type.name}
      </h4>
      <h3>{clase.description}</h3>
      <p className="large">
        <i className="far fa-calendar me-2"></i>{" "}
        {moment(clase.class_date).utc().format("DD MMM YYYY")}{" "}
        <i className="far fa-clock mx-2"></i>
        {moment(clase.class_date).utc().format("HH:mm")}
        hrs.
      </p>
      <h5 className="mt-4">Coaches</h5>
      {renderCoaches()}
      <h5>Ubicación</h5>
      <p className="large">{clase.location.name}</p>
      <p>{clase.location.address}</p>
      {clase.class_type !== null && clase.class_type.spot_map !== null && (
        <MapaLugares
          place={place}
          setPlace={setPlace}
          rows={clase.class_type.spot_map
            .split(",")
            .map((num) => parseInt(num))}
          icon={clase.icon}
          taken_spots={
            Array.isArray(clase.class_reservations)
              ? clase.class_reservations.map(({ spot }) => spot)
              : Array.isArray(clase.taken_spots)
              ? clase.taken_spots
              : []
          }
        />
      )}
      {isGuest && (
        <div>
          <label className="bold">Nombre de Acompañante</label>
          <input
            type="text"
            value={guest}
            className="form-control"
            placeholder="Juan Pérez"
            onChange={(e) => setGuest(e.target.value)}
          />
        </div>
      )}
      {error !== null && <p className="text-danger mb-1">{error}</p>}
      <div className="row mt-2">
        <div className="col col-md-6">
          <button disabled={spinner} className="btn btn-accent" onClick={handleSubmit}>
            {spinner ? <div className="spinner-border" /> : "Reservar"}
          </button>
        </div>
        <div className="col col-md-6 text-right pe-0">
          <button className="btn btn-link text-muted" onClick={hideModal}>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleClass;


import React, { createContext, useReducer, useContext } from 'react';
import AppConfigService from '../services/AppConfigService';
import AppConfigReducer from '../reducers/AppConfigReducer';
import {
  APPCONFIG_RECEIVED,
  SET_APPCONFIG,
  CREATE_APPCONFIG,
  SET_PROPERTY_APPCONFIG,
} from "../types/appconfig";

import { ModalContext } from './ModalContext';

const initialState = {

};

export const AppConfigContext = createContext(initialState);

export const AppConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppConfigReducer, initialState);
 
  const { alert, success, clearModal } = useContext(ModalContext);

  const getAppConfig = () => {
    AppConfigService.getAppConfig()
      .then((response) => {
        const { config } = response.data;
        dispatch({ type: APPCONFIG_RECEIVED, payload: config });
      })
      .catch((error) => {
        alert(error);
      });
  };


  return (
    <AppConfigContext.Provider
    value={{
      ...state,
      getAppConfig
    }}
  >
    {children}
  </AppConfigContext.Provider>
);
};

import React, { useContext } from "react";
import { Router } from "@reach/router";
import Shop from "./Shop";
import { AuthContext } from "../context/AuthContext";
import Presenciales from "./Presenciales";
import MiInformacion from "./MiInformacion";
import MisMetodos from "./MisMetodos";
import MobileMenu from "../components/common/MobileMenu";
import MisReservaciones from "./MisReservaciones";
import PanelNavbar from "../components/global/PanelNavbar";
import Footer from "../components/global/Footer";
import Purchases from "./Purchases";

const Panel = () => {
  const { user, signOut } = useContext(AuthContext);

  return (
    <div className="container-fluid px-0">
      <MobileMenu signOut={signOut} user={user} />
      <PanelNavbar user={user} signOut={signOut} />
      <div className="main-panel">
        <div className="content overflow-x-hidden">
          <Router>
            <Presenciales path="/" />
            <Shop path="/shop" />
            <MiInformacion path="/informacion" />
            <MisMetodos path="/metodos" />
            <MisReservaciones path="/reservaciones" />
            <Purchases path="/pagos" />
          </Router>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Panel;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Link, navigate } from "@reach/router";
import { useUserPermissions } from "../../hooks/useUserPermissions";

const CustomerBilling = ({}) => {
  const [editMode, setEditMode] = useState(false);
  const [inputs, setInputs] = useState([
    {
      key: "country",
      label: "País",
      type: "text",
      value: "",
      cols: 6,
      required: true,
      valid: true,
    },
    {
      key: "city",
      label: "Ciudad",
      type: "text",
      value: "",
      cols: 6,
      required: true,
      valid: true,
    },
    {
      key: "state",
      label: "Estado",
      type: "text",
      value: "",
      cols: 6,
    },
    {
      key: "neighborhood",
      label: "Colonia",
      type: "text",
      value: "",
      cols: 6,
    },
    {
      key: "street",
      label: "Calle",
      type: "text",
      value: "",
      cols: 6,
    },
    {
      key: "number",
      label: "No.",
      type: "text",
      value: "",
      cols: 6,
    },
    {
      key: "zip_code",
      label: "Código Postal",
      type: "number",
      value: "",
      cols: 6,
    },
  ]);

  const { hasValidBillingAddress } = useUserPermissions();

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  useEffect(() => {
    if (user.address) {
      setCurrentData();
    }
  }, [user.address]);

  const setCurrentData = () => {
    const address = user.address;
    const updatedInputs = inputs.map((input, index) => {
      const value = address[input.key];

      if (input.required && value?.length <= 0) {
        return {
          ...input,
          value: value ? value : "",
          valid: false,
        };
      } else {
        return {
          ...input,
          value: value ? value : "",
        };
      }
    });

    setInputs(updatedInputs);
  };

  const validateRequiredInputs = () => {
    let valid = true;

    inputs.map((input) => {
      if (input.required && input.valid === false) valid = false;
    });

    return valid;
  };

  const getFormData = () => {
    const data = {};
    inputs.map((input) => {
      data[input.key] = input.value;
    });

    return data;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validInputs = validateRequiredInputs();

    if (validInputs) {
      const formData = getFormData();
      const currentAddress = user.address;
      setEditMode(false);

      if (currentAddress) {
        user.address = {
          ...currentAddress,
          ...formData,
        };
      } else {
        user.address = {
          ...formData,
        };
      }

      updateUsuario(user);
    }
  };

  const handleChangeInput = (index, value) => {
    const validLength = value.length > 0;
    const updatedInputs = [...inputs];
    const currentInput = updatedInputs[index];
    currentInput.value = value;

    if (!validLength && currentInput.required) {
      currentInput.valid = false;
    }

    if (validLength && currentInput.required) {
      currentInput.valid = true;
    }

    setInputs(updatedInputs);
  };

  const renderInputs = () => {
    return inputs.map((input, index) => {
      return (
        <div key={input.key} className={`mb-2 col-${input.cols}`}>
          <label className="bold">{input.label}</label>
          <input
            onChange={(event) => handleChangeInput(index, event.target.value)}
            className={`form-control ${
              input.valid === false ? "border-danger" : ""
            }`}
            type={input.type}
            value={input.value}
          />
        </div>
      );
    });
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">{renderInputs()}</div>

        <div className="row">
          <div className="col-12 text-end">
            <button
              type="button"
              className="btn btn-link text-muted mt-3 me-2"
              onClick={() => setEditMode(false)}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary mt-3">
              Guardar
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderData = () => {
    const validAddress = hasValidBillingAddress();
    const city = user.address?.city;
    const state = user.address?.state;
    const country = user.address?.country;

    let address = "";
    if (city) address = `${city}, `;
    if (state) address += `${state}, `;
    if (country) address += `${country}`;

    if (!validAddress) address = "No has completado los campos requeridos.";

    return (
      <>
        <div className="row">
          <div className={`col ${!validAddress ? "text-danger" : ""}`}>
            {address}
          </div>
        </div>

        <div className="row">
          <div className="col">
            <button
              type="button"
              className="btn btn-primary mt-3"
              onClick={() => setEditMode(true)}
            >
              <i className="fa fa-edit"></i> Editar
            </button>
          </div>
        </div>
      </>
    );
  };

  const renderContent = () => {
    if (editMode) {
      return renderForm();
    } else {
      return renderData();
    }
  };

  return (
    <div className="card px-0 no-scale shadow-sm">
      <div className="container-fluid">
        <h4 className="pb-2 border-bottom">Facturación</h4>

        <div className="row mb-3">
          <div className="col-12">
            <Link className="btn w-100 text-start px-1" to={"/mybikevibe/pagos"}>
              <i className="fas fa-shopping-basket me-2"></i> Mis Compras
            </Link>
          </div>

          <div className="col-12">
            <Link className="btn w-100 text-start px-1" to={"/mybikevibe/reservaciones"}>
              <i className="fas fa-calendar me-2"></i> Mis Reservaciones
            </Link>
          </div>
        </div>
        {/* 
        <h4 className="pb-2 border-bottom">
          Dirección de Facturación
        </h4>

        {renderContent()} */}
      </div>
    </div>
  );
};

export default CustomerBilling;

import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"


export const useUserPermissions = () => {
  const { user } = useContext(AuthContext);

  const hasValidBillingAddress = () => {
    const address = user.address;
    const city = address?.city;
    const country = address?.country;
    let validBillingAddress = true;

    if(address === null || address === undefined) {
      validBillingAddress = false;
    }

    if(city === undefined || city === null || city?.length <= 0) {
      validBillingAddress = false;
    }

    if(country === undefined || country === null || country?.length <= 0) {
      validBillingAddress = false;
    }

    return validBillingAddress;
  }

  return {
    hasValidBillingAddress
  }

}
import { ClassInstructorProvider } from "./context/ClassInstructorContext";
import { CoachesProvider } from "./context/CoachesContext";
import { CustomerProvider } from "./context/CustomerContext";
import { HomeProvider } from "./context/HomeContext";
import { LocationsProvider } from "./context/LocationsContext";
import { ModalProvider } from "./context/ModalContext";
import { PurchaseProvider } from "./context/PurchasesContext";
import { PaquetesProvider } from "./context/PackageContext";
import { UserProvider } from "./context/AuthContext";
import { ClassTypeProvider } from "./context/ClassTypesContext";
import { ClassCategoryProvider } from "./context/ClassCategoryContext";
import { CheckoutProvider } from "./context/CheckoutContext";
import { DiscountsProvider } from "./context/DiscountsContext";
import { MetodosProvider } from "./context/MetodosContext";
import { ReservationsProvider } from "./context/ReservationsContext";
import { combineComponents } from "./context";
import moment from "moment";
import Main from "./Main";
import "./index.css";
import { PaymentSourcesProvider } from "./context/PaymentSourcesContext";
import { AppConfigProvider } from "./context/AppConfigContext";
import { AddressProvider } from "./context/AddressContext";
import { PagesProvider } from "./context/PagesContext";
import { AvailableClassesProvider } from "./context/AvailableClassesContext";

moment.updateLocale("es", {
  weekdays: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
});

const AppContextProvider = combineComponents([
  HomeProvider,
  CoachesProvider,
  MetodosProvider,
  PaquetesProvider,
  CheckoutProvider,
  PurchaseProvider,
  CustomerProvider,
  DiscountsProvider,
  LocationsProvider,
  ClassTypeProvider,
  ReservationsProvider,
  ClassCategoryProvider,
  PaymentSourcesProvider,
  ClassInstructorProvider,
  AvailableClassesProvider,
  AddressProvider,
  PagesProvider
]);

function App() {
  return (
    <ModalProvider>
      <AppConfigProvider>
        <UserProvider>
          <AppContextProvider>
            <Main />
          </AppContextProvider>
        </UserProvider>
      </AppConfigProvider>
    </ModalProvider>
  );
}

export default App;

import api from "./api";

const route = "/checkout";

const CheckoutService = {
  attempt: (class_package_id, discountCode, payment_method) =>
    api.post(`${route}/attempt`, {
      class_package_id,
      discountCode,
      payment_method,
    }),
  postCheckout: (class_package_id, discountCode, payment_source_id) =>
    api.post(`${route}/attempt`, {
      payment_source_id,
      class_package_id,
      discountCode,
    }),
  postPayPal: (packageClassId, discountCode) =>
    api.post(`${route}/paypal`, { packageClassId, discountCode }),
  capturePayPal: ({ orderID }) => api.post(`${route}/capture`, { orderID }),
};

export default CheckoutService;

import React, { useContext, useEffect } from "react";
import PurchaseCard from "../components/purchases/PurchaseCard";
import { PurchasesContext } from "../context/PurchasesContext";
import PurchasesTable from "../components/purchases/PurchasesTable";
import PurchasesList from "../components/purchases/PurchasesList";

const Purchases = () => {
  const { purchases, getPurchases } = useContext(PurchasesContext);

  useEffect(() => {
    getPurchases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderOrdenes = () => {
    if (purchases && purchases !== null) {
      if (purchases.length === 0) {
        return <p>No haz hecho ninguna compra.</p>;
      }
      return purchases.map((purchase) => (
        <PurchaseCard key={purchase.purchase_id} purchase={purchase} />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <div className="mx-0 row align-items-center mb-3">
        <div className="container-fluid px-0">
          <h2>Mis Compras</h2>
        </div>
      </div>
      <PurchasesTable purchases={purchases}/>
      <PurchasesList purchases={purchases}/>

    </div>
  );
};

export default Purchases;

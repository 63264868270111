import React, { useContext } from "react";
import moment from "moment";
import { hideModal } from "../../utils";
import { ReservationsContext } from "../../context/ReservationsContext";

const CancelClass = ({ singleClass, handleCallback }) => {
  const { reservations, cancelReservacion } = useContext(ReservationsContext);

  const getClassReservation = () => {
    if (singleClass) {
      if (singleClass.class_reservation_id) {
        return singleClass.class_reservation_id;
      }
    }
    if (reservations && reservations !== null) {
      const class_reservation = reservations
        .filter((reservation) => reservation.deletedAt === null)
        .find(
          (reservation) =>
            parseInt(reservation.single_class_id) ===
            parseInt(singleClass.single_class_id)
        );
      if (class_reservation) {
        return class_reservation.class_reservation_id;
      }
    }
  };

  const class_reservation_id = getClassReservation();

  return (
    <div className="container-fluid px-0">
      <p>
        ¿Deseas cancelar tu clase {singleClass.class_type?.name} del{" "}
        {moment(singleClass.class_date).utc().format("DD MMM YYYY")} a las{" "}
        {moment(singleClass.class_date).utc().format("HH:mm")}
      </p>
      <div className="row">
        <div className="col col-md-6">
          <button
            className="btn btn-danger"
            onClick={() => cancelReservacion(class_reservation_id, handleCallback)}
          >
            Cancelar Clase
          </button>
        </div>
        <div className="col col-md-6 text-right">
          <button className="btn btn-link text-secondary" onClick={hideModal}>
            Regresar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CancelClass;

export const handleCheckoutError = (error, callback) => {
  if (error.response) {
    const { data } = error.response;
    if (data) {
      if (data.error) {
        const { code, decline_code } = data.error;
        if (code && code !== null) {
          let message = "Lo siento bebé, hubo un error al procesar tu tarjeta.";
          if (code === "card_declined") {
            if (decline_code === "insufficient_funds") {
              message =
                "Lo siento bebé, tu tarjeta no tiene fondos suficientes.";
            } else {
              message =
                "Lo siento bebé, tu compra ha sido rechazada por el banco. Intenta más tarde o con otra tarjeta.";
            }
          } else if (code === "expired_card") {
            message = "Lo siento bebé, tu tarjeta ha expirado.";
          } else if (code === "incorrect_number") {
            message = "Lo siento bebé, el número de tu tarjeta es incorrecto.";
          }
          return callback(message);
        }
      }
    }
    if (error.response.status === 412) {
      if (error.response.data) {
        if (error.response.data.message) {
          return callback(error.response.data.message);
        }
      }
      return callback("Lo sentimos. Este evento especial se ha llenado.");
    } else if (error.response.status === 409) {
      let message =
        "Lo siento bebé, ya has alcanzado el límite de compras de esta certificación.";
      if (error.response.data && error.response.data !== null) {
        if (
          error.response.data.message &&
          error.response.data.message !== null
        ) {
          message = error.response.data.message;
        }
      }
      return callback(message);
    } else if (error.response.status === 402) {
      return callback(
        "Lo sentimos. Tu tarjeta fue rechazada por fondos insuficientes."
      );
    }
  }
  callback("Intenta de nuevo o contáctanos para más información.");
};

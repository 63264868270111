import React from "react";
import Footer from "../components/global/Footer";
import Schedule from "../components/schedule/Schedule";
import HomeCoaches from "../components/coaches/HomeCoaches";
import HomeClassPackages from "../components/paquetes/HomeClassPackages";
import { Link } from "@reach/router";

const Landing = () => {
  return (
    <div id="landing">
      <div id="video" className="row mx-0 mt-3 align-items-center">
        <video
          className="mw-100 w-100 home-video px-0"
          autoPlay
          data-autoplay
          playsInline
          loop
          muted
          data-object-fit="cover"
        >
          <source src="/video/home.mp4" />
        </video>
        <div className="container-fluid text-center main-cta-cont">
          <h1 className="text-center display-1">
            RIDE WITH US & <br />{" "}
            <span className="text-primary">FEEL THE VIBE</span>
          </h1>
          <Link
            id="main-cta"
            to="/entrar"
            className="btn btn-accent bold btn-lg m-auto landing-button"
          >
            Prueba 1 Clase Gratis
          </Link>
        </div>
      </div>
      <div
        id="about"
        style={{ backgroundImage: 'url("/images/bg-cintas.png")' }}
        className="about-vibe py-md-5 align-items-center d-flex"
      >
        <div className="container py-5">
          <div className="row align-items-center h-100">
            <div className="col-12 col-md-6">
              <div className="pe-5">
                <img
                  src="/images/logo-transparente.png"
                  className="mb-3"
                  style={{ maxWidth: 400, marginLeft: -22 }}
                />
                <p>
                  Somos una comunidad apasionada por el Indoor Cycling y
                  queremos fomentar el bienestar en la salud y la confianza en
                  uno mismo, es por eso que en Bike Vibe te ofrecemos la mejor
                  experiencia para tu salud física y mental rodando al ritmo de
                  la música.
                </p>
                <Link to="/registro" className="btn px-4 mb-4 btn-primary">
                  Prueba 1 Clase Gratis
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <img
                src="/images/bg-about.jpeg"
                className="mw-100 w-100 d-block m-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container px-2 p-4" id="calendario">
        <h2 className="mb-0">Calendario de Clases</h2>
        <Schedule isHome={true} hidePrevious />
      </div>
      <HomeClassPackages />
      <HomeCoaches />
      <div className="bg-black py-5 container-fluid">
        <div className="scroll-wrapper">
          <div className="scroll-wrapper-inner">
            <img
              src="/images/banner-1.jpg"
              className="me-3 scroll-image scroll-item"
            />
            <img
              src="/images/banner-2.jpeg"
              className="me-3 scroll-image scroll-item"
            />
            <img
              src="/images/banner-3.jpeg"
              className="me-3 scroll-image scroll-item"
            />
            <img
              src="/images/banner-4.jpeg"
              className="me-3 scroll-image scroll-item"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;

import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { formatMonto, S3_ENDPOINT } from "../../utils";

const CheckoutClassPackage = ({ class_package_id }) => {
  const { descuento, paquete, getPaquete } = useContext(CheckoutContext);

  useEffect(() => {
    getPaquete(class_package_id);
  }, []);

  const renderResultadoDescuento = () => {
    if (descuento && descuento !== null) {
      let total = paquete.price;
      if (descuento.is_percent) {
        let porcentaje = parseFloat(1 - descuento.amount / 100);
        total = parseFloat(paquete.price) * porcentaje;
      } else {
        total = paquete.price - descuento.amount;
      }
      total = parseFloat(total).toFixed(2);
      return (
        <div className="container-fluid px-0">
          <h5>
            Total con Descuento: {"$"}
            {total} MXN {renderPeriod()}
          </h5>
        </div>
      );
    }
  };

  const renderDiscountPeriod = () => {
    if (descuento && descuento !== null) {
      if (descuento.first_invoice_only) {
        return `tu primer pago. Después $${formatMonto(
          paquete.price
        )} MXN ${renderPeriod()}`;
      }
    }
  };

  const renderPeriod = () => {
    if (paquete.subscription_period === "month") {
      return "por mes";
    }
  };

  const renderImage = () => {
    const thumbnail = paquete.image;
    if (thumbnail && thumbnail !== null) {
      return (
        <img
          src={thumbnail.src}
          style={{objectFit: 'cover'}}
          className="paquete-img mb-3 w-100 h-auto "
          alt="package thumbnail"
        />
      );
    }
  };

  const renderDescription = () => {
    const htmlRegex = /<([a-z]+)\s*\/?[^>]*>/i;
    const isHTML = htmlRegex.test(paquete.description);

    if(isHTML) {
      return(
        <div
          dangerouslySetInnerHTML={{ __html: paquete.description}}
          style={{ overflowY: "auto", flex: 1 }}
        ></div>
      )
    } else {
      return <p>{paquete.description}</p>
    }
  }


  const renderpaquete = () => {
    if (paquete && paquete !== null) {
      const price =
        paquete.sale_price !== null && paquete.sale_price !== ""
          ? paquete.sale_price
          : paquete.price;
      return (
        <div>
          {renderImage()}
          <h3>{paquete.title}</h3>
          {renderDescription()}
          <p>{paquete.short_description}</p>
          <h5>
            Total: {"$"}
            {formatMonto(price)}
            {" MXN"} {renderPeriod()}
          </h5>
          {renderResultadoDescuento()}
        </div>
      );
    }
  };

  return (
    <div>
      <h3>Estás Comprando</h3>
      <div className="mb-3 card no-scale">{renderpaquete()}</div>
    </div>
  );
};

export default CheckoutClassPackage;

import React, { useState, useEffect, useContext } from "react";
import { ClassInstructorContext } from "../../context/ClassInstructorContext";
import { ReservationsContext } from "../../context/ReservationsContext";
import ScheduleMonthSelect from "./ScheduleMonthSelect";
import ScheduleWeekSelect from "./ScheduleWeekSelect";
import ScheduleDesktop from "./ScheduleDesktop";
import ColorLegend from "../global/ColorLegend";
import ScheduleMobile from "./ScheduleMobile";
import utils from "./utils";

const Schedule = () => {
  const [view, setView] = useState("week");
  const [currentClasses, setCurrentClasses] = useState(null);

  const { updated } = useContext(ReservationsContext);

  const { days, start_date, end_date, setStartDate, setEndDate, getSchedule } =
    useContext(ClassInstructorContext);

  useEffect(() => {
    const date = utils.getStartDate(view);
    setStartDate(date);
  }, []);

  useEffect(() => {
    setEndDate(utils.getEndDate(view, start_date));
  }, [start_date]);

  useEffect(() => {
    if (Array.isArray(days)) {
      if (view === "week") {
        setCurrentClasses(days.slice(0, 7));
      } else {
        setCurrentClasses(days);
      }
    }
  }, [days]);

  useEffect(() => {
    fetchSchedule();
  }, [start_date, end_date]);

  const fetchSchedule = () => {
    if (utils.validDates(start_date, end_date)) {
      getSchedule(start_date, end_date);
    }
  };

  const handleNextWeek = () => {
    setStartDate(utils.getStartNext("week", start_date));
  };

  const handlePrevWeek = () => {
    setStartDate(utils.getStartPrev("week", start_date));
  };

  const handleNextMonth = () => {
    setStartDate(utils.getStartNext("month", start_date));
  };

  const handlePrevMonth = () => {
    setStartDate(utils.getStartPrev("month", start_date));
  };

  return (
    <div className="container-fluid px-0" style={{ overflowX: "hidden" }}>
      <div className="container-fluid">
        <div className="row align-items-center mt-3 mb-4">
          <div className="col-12 col-md-4">
            <ScheduleMonthSelect
              start_date={start_date}
              handleNext={handleNextMonth}
              handlePrev={handlePrevMonth}
            />
            <ColorLegend />
          </div>
          <div className="col-12 col-md-8 px-0">
            <ScheduleWeekSelect
              start_date={start_date}
              handleNextWeek={handleNextWeek}
              handlePrevWeek={handlePrevWeek}
            />
          </div>
        </div>
      </div>
      <ScheduleDesktop weekClasses={currentClasses} />
      <ScheduleMobile weekClasses={currentClasses} />
    </div>
  );
};

export default Schedule;

import React, { useEffect, useContext } from "react";
import PaqueteCard from "./PaquetesCard";
import { navigate } from "@reach/router";
import { PackagesContext } from "../../context/PackageContext";

const HomeClassPackages = () => {
  const { paquetes, getPaquetes } = useContext(PackagesContext);

  useEffect(() => {
    getPaquetes();
  }, []);

  const toCheckout = (class_package_id) => {
    navigate(`/checkout/${class_package_id}`);
  };

  const renderPaquetes = () => {
    if (paquetes && paquetes !== null) {
      return paquetes.map((paquete) => (
        <PaqueteCard
          key={paquete.class_package_id}
          paquete={paquete}
          action={() => toCheckout(paquete.class_package_id)}
        />
      ));
    }
  };

  return (
    <div className="container px-2 p-4" id="paquetes">
      <h2 className="mb-0">Paquetes de Clases</h2>
      <div className="row">{renderPaquetes()}</div>
    </div>
  );
};

export default HomeClassPackages;


import {
  SET_PAGE,
  CREATE_PAGE,
  PAGES_RECEIVED,
  SET_PROPERTY_PAGE,
} from "../types/Pages";

const schema = {

}

const PagesReducer = (state, { type, payload }) => {
  switch (type) {
    case PAGES_RECEIVED:
      return { ...state, pages: payload };
    case SET_PAGE:
      return { ...state, page: payload };
    case CREATE_PAGE:
      return { ...state, page: schema };
    case SET_PROPERTY_PAGE: {
      const { key, value } = payload;
      const Page = { ...state.Page };
      Page[key] = value;
      return { ...state, Page };
    }
    default:
      return { ...state};
  }
};

export default PagesReducer;

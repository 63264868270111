import React, { useEffect } from "react";
import { Router } from "@reach/router";
import Home from "./views/Home";
import Login from "./views/Login";
import SignUp from "./views/SignUp";
import Recuperar from "./views/Recuperar";
import Panel from "./views/Panel";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import Modal from "./components/common/Modal";
import SuccessAlert from "./components/common/SuccessAlert";
import ErrorAlert from "./components/common/ErrorAlert";
import Gracias from "./views/Gracias";
import Loading from "./views/Loading";
import moment from "moment";
import Checkout from "./views/Checkout";
import Terms from "./views/pages/Terms";
import Privacy from "./views/pages/Privacy";
import Especiales from "./views/Especiales";
import { AppConfigContext } from "./context/AppConfigContext";

const Main = () => {
  const { user, userLoggedIn } = useContext(AuthContext);
  const { getAppConfig } = useContext(AppConfigContext);

  useEffect(() => {
    getAppConfig();
    userLoggedIn();
    moment.updateLocale("es", {
      weekdays: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
    });
  }, []);

  return (
    <div className="container-fluid px-0">
      <Router>
        <Home path="/*" />
        <Especiales path="/especiales" />
        <Login path="/entrar" />
        <SignUp path="/registro" />
        <Recuperar path="/recuperar" />
        <Checkout path="/checkout/:class_package_id" />
        <Gracias path="/gracias/:purchase_id" />
        <Terms path="/terminos-y-condiciones" />
        <Privacy path="/privacidad" />
        {user !== null ? (
          <Panel path="/mybikevibe/*" />
        ) : (
          <Loading path="/mybikevibe/*" />
        )}
      </Router>
      <Modal />
      <SuccessAlert />
      <ErrorAlert />
    </div>
  );
};

export default Main;

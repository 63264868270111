import React, { useContext, useEffect } from "react";
import { CheckoutContext } from "../context/CheckoutContext";
import { AuthContext } from "../context/AuthContext";
import CheckoutHeader from "../components/checkout/CheckoutHeader";
import CheckoutClassPackage from "../components/checkout/CheckoutClassPackage";
import CheckoutDiscount from "../components/checkout/CheckoutDiscount";
import CheckoutAccount from "../components/checkout/CheckoutAccount";
import CheckoutPaymentMethods from "../components/checkout/CheckoutPaymentMethods";
import Footer from "../components/global/Footer";

const Checkout = ({ class_package_id }) => {
  const { user } = useContext(AuthContext);

  //Checkout
  const {
    course,
    descuento,
    setPayPal,
    setDescuento,
    discountCode,
    setPaymentSource,
    payment_source_id,
  } = useContext(CheckoutContext);

  useEffect(() => {
    return () => {
      setDescuento(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (descuento !== null && course !== null) {
      setPayPal(class_package_id, discountCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descuento]);

  useEffect(() => {
    const paypalButton = document.getElementById("paypal-button");
    if (user !== null && course !== null && paypalButton !== null) {
      setPayPal(class_package_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, course]);

  const renderBillingDisclaimer = () => {
    if (course && course !== null) {
      if (course.subscription_period !== null) {
        return (
          <div className="card p-4 border br-10 no-scale mb-4">
            <h4>Recuerda</h4>
            <p>
              * Tu suscripción se renovará automáticamente cada mes hasta que la
              canceles. Puedes cancelar en cualquier momento, al cancelar,
              pierdes tu licencia para impartir clases del método Latina Tribe.
              Los cobros por las suscripciones no son reembolsables. Al realizar
              tu compra nos autorizas a cobrar a través de tu tarjeta de crédito
              o débito, aceptas nuestros Términos de servicio, Políticas de
              cookies y de privacidad.
            </p>
            <p></p>
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid px-0">
      <CheckoutHeader />
      <div className="container">
        <div className="row pt-4 pb-5">
          <div className="col-12 col-md-6 col-xl-4 my-2">
            <CheckoutClassPackage class_package_id={class_package_id} />
            <CheckoutDiscount class_package_id={class_package_id} />
          </div>
          <div className="col-12 col-md-6 col-xl-8 my-2">
            <CheckoutAccount />
            <CheckoutPaymentMethods
              class_package_id={class_package_id}
              paymentMethod={payment_source_id}
              setPaymentMethod={setPaymentSource}
            />
          </div>
        </div>
        {renderBillingDisclaimer()}
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;

import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getValue, grayImg } from "../../utils";

const CustomerInfo = ({}) => {
  const [editMode, setEditMode] = useState(false);
  const [changeImage, setChangeImage] = useState(false);

  const { user, getUsuario, updateUsuario, setPropiedadUser } =
    useContext(AuthContext);

  useEffect(() => {
    if (!user.file?.src) {
      setChangeImage(true);
    }
  }, [user]);

  const handleSubmit = (e) => {
    setEditMode(false);
    e.preventDefault();
    const data = { ...user };
    delete data.class_reservations;
    delete data.available_classes;
    delete data.purchases;
    updateUsuario(data);
  };

  const handleFiles = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentSrc = e.target.result;
        setPropiedadUser("file", {
          newFile: file,
          src: currentSrc,
        });
        setChangeImage(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const renderImage = () => {
    const userImg = user.file?.src;
    const imgSrc = userImg ? userImg : grayImg;

    return (
      <div style={{ width: "max-content" }} className="">
        <img
          src={imgSrc}
          className="video-form-thumbnail d-block my-3 bg-light border"
          alt="Perfil"
        />
      </div>
    );
  };

  const renderImageForm = () => {
    if (changeImage) {
      return (
        <input
          type="file"
          className="form-control mb-3"
          onChange={handleFiles}
        />
      );
    }
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-dark d-block"
          onClick={() => setChangeImage(true)}
        >
          Cambiar Imagen
        </button>
        <button
          type="button"
          className="btn btn-outline-danger mt-5"
          onClick={() => {
            setPropiedadUser("file", null);
            setPropiedadUser("file_id", null);
            setChangeImage(true);
          }}
        >
          Quitar Imagen
        </button>
      </>
    );
  };

  const renderForm = () => {
    const { name, last_name, instagram, phone, shoes, shirt } = user;

    return (
      <form onSubmit={handleSubmit}>
        <div className="row justify-content-between">
          {renderImage()}
          <div
            style={{
              width: "calc(100% - 230px)",
            }}
          >
            <label>Foto</label>
            {renderImageForm()}
          </div>
        </div>
        <label>Nombre <span className="small text-danger">* (requerido)</span></label>
        <input
          required
          type="text"
          className="form-control mb-3"
          value={name}
          onChange={(e) => setPropiedadUser("name", e.target.value)}
        />
        <label>Apellidos <span className="small text-danger">* (requerido)</span></label>
        <input
          required
          type="text"
          className="form-control mb-3"
          value={last_name}
          onChange={(e) => setPropiedadUser("last_name", e.target.value)}
        />
        <label>Instagram</label>
        <input        
          type="text"
          className="form-control mb-3"
          value={instagram !== null ? instagram : ""}
          onChange={(e) => setPropiedadUser("instagram", e.target.value)}
        />
        <div className="row">
          <div className="col-6">
            <label>Fecha de Nacimiento <span className="small text-danger">* (requerido)</span></label>
            <input
              required
              type="date"
              value={getValue(user, "birthdate")}
              className="form-control mb-3"
              onChange={(e) => setPropiedadUser("birthdate", e.target.value)}
            />
          </div>
          <div className="col-6">
            <label>Telefono (WhatsApp) <span className="small text-danger">* (requerido)</span></label>
            <input
              required
              type="tel"
              className="form-control mb-3"
              value={phone}
              onChange={(e) => setPropiedadUser("phone", e.target.value)}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <label>Talla de Camiseta</label>
            <input
              type="text"
              value={shirt}
              className="form-control mb-3"              
              onChange={(e) => setPropiedadUser("shirt", e.target.value)}
            />
          </div>

          <div className="col-6">
            <label>Talla de Calzado</label>
            <input
              type="tel"
              className="form-control mb-3"
              value={shoes}
              onChange={(e) => setPropiedadUser("shoes", e.target.value)}
            />
          </div>
        </div>

        <div className="row mt-2">
          <div className="col col-12 text-end">
            <button
              className="btn btn-link text-muted"
              onClick={() => {
                setEditMode(false);
                getUsuario();
              }}
            >
              Cancelar
            </button>

            <button type="submit" className="btn btn-primary">
              Guardar
            </button>
          </div>
        </div>
      </form>
    );
  };

  const renderName = () => {
    const { name, last_name } = user;
    if (name !== null && last_name !== null) {
      return <h4 className="mb-0">{name} {last_name}</h4>
    }
    return <span className="text-danger">No has agregado tu nombre</span>;
  };

  const renderData = () => {
    const { birthdate, instagram, phone, email } = user;

    if (editMode) {
      return renderForm();
    } else {
      return (
        <div className="container-fluid px-0">
          <div className="row">
            {renderImage()}
            <div style={{ flex: 1, minWidth: "430px" }}>
              <div className="mb-3 pb-3 border-bottom">
                {renderName()}
              </div>
              <div className="row">
                <div className="col-1">
                  <i className="fa fa-birthday-cake"></i>
                </div>
                <div className="col-11">
                  {moment(birthdate).isValid() ? (
                    moment(birthdate).utc().format("DD MMM YYYY")
                  ) : (
                    <p className="mb-0 text-danger">
                      No has cargado esta información
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="col-11">{email}</div>
              </div>
              <div className="row">
                <div className="col-1">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="col-11">
                  {phone !== null ? (
                    phone
                  ) : (
                    <p className="mb-0 text-danger">
                      No has cargado esta información
                    </p>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-1">@</div>
                <div className="col-11">
                  {instagram !== null ? instagram : ""}
                </div>
              </div>
            </div>

            <div className="col-12">
              <button
                className="btn btn-primary mt-3"
                onClick={() => setEditMode(true)}
              >
                <i className="fa fa-edit"></i> Editar Información
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderData();
};

export default CustomerInfo;

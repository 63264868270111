import { Link } from "@reach/router";
import React from "react";
import { BASE_URL } from "../../utils";

const HomeCoachCard = ({ coach }) => {
  const { name, last_name, file } = coach;
  return (
    <Link
      to={`/coach/${coach.instructor_id}`}
      className="col-12 col-md-4 col-lg-3 p-4 no-decoration text-dark"
    >
      <div className="card bg-white shadow-sm px-0 py-0 text-center">
        {file && file !== null && (
          <img
            src={`${BASE_URL}/files/${file.name}.${file.type}`}
            className="mw-100 w-100 coach-img"
          />
        )}
        <div className="pt-3">
          <h4>
            {coach.nick_name !== null ? (
              coach.nick_name
            ) : (
              <>
                {name} {last_name !== null ? last_name : ""}
              </>
            )}
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default HomeCoachCard;

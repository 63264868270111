import React from "react";
import PurchaseCard from "./PurchaseCard";

const PurchasesList = ({ purchases, confirmCancel }) => {
  const renderOrdenes = () => {
    if (Array.isArray(purchases)) {
      if (purchases.length === 0) {
        return <p>No has hecho ninguna compra.</p>;
      }
      return purchases.map((purchase) => (
        <PurchaseCard
          key={purchase.purchase_id}
          purchase={purchase}
          cancelSubscription={confirmCancel}
        />
      ));
    }
  };
  return <div className="show-mobile">{renderOrdenes()}</div>;
};
export default PurchasesList;
